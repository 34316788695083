<template>
    <div>
        <el-card class="box-card">
            <div slot="header" >
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>系统管理</el-breadcrumb-item>
                    <el-breadcrumb-item>用户管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-form :model="queryCommodityForm" size="small" ref="queryCommodityForm" class="demo-ruleForm">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <el-form-item  prop="userName">
                                <el-input clearable v-model="queryCommodityForm.userName" style="width: 150px" placeholder="输入账号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-button type="primary" size="small" @click="getUserList()">查询</el-button>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" size="small" @click="show()">添加</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table
                        height="450"
                        :data="UserList"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="userName"
                            label="登录账号"
                    >
                    </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    type="success"
                                    @click="editUser(scope.row)">修改</el-button>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    @click="deleteUser(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-left: 5rem; margin-top: 1em">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageNum"
                            :page-sizes="[8,20,50,100]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-card>
        <el-dialog
                title="添加用户"
                :visible.sync="dialogVisible"
                width="60%"
                @close="dialogColsed"
        >
            <el-form :model="regForm" size="small" :rules="regRules" ref="regForm">
                <el-form-item  prop="userName">
                    <el-input v-model="regForm.userName" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item  prop="password">
                    <el-input v-model="regForm.password" placeholder="请输入密码" ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitRegForm('regForm')">确 定</el-button>
  </span>
        </el-dialog><el-dialog
            title="修改用户"
            :visible.sync="editDdialogVisible"
            width="40%"
            @close="editDdialogVisibleColsed"
    >
        <el-form :model="editForm" :rules="regRules" size="small" ref="editForm">
            <el-form-item  prop="userName">
                <el-input v-model="editForm.userName" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item  prop="password">
                <el-input v-model="editForm.password" placeholder="请输入密码" ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="editDdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitEditForm('editForm')">确 定</el-button>
  </span>
    </el-dialog>

    </div>
</template>

<script>
    export default {
        data() {

            return {
                regRules:
                    {
                        userName: [
                            { required: true, message: '请输入用户名', trigger: 'blur' },
                            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                        ],
                        password: [
                            { required: true, message: '请输入密码', trigger: 'blur' },
                            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                        ]


                    },
                queryCommodityForm: {
                    userName: ''
                },
                UserList: [],
                regForm: {
                    userName:'',
                    password:'',
                },
                editForm:{
                    userName:'',
                    password:'',
                },
                total: 0,
                pageNum: 1,
                pageSize: 8,
                fileList:[],
                dialogVisible:false,
                editDdialogVisible: false
            }
        },
        created() {
            this.getUserList()
        },
        methods: {
            // 关闭对话框清空数据
            dialogColsed() {
                this.$refs.regForm.resetFields();

            },
            handleSizeChange(pageSize) {
                this.pageSize = pageSize;
                this.getUserList();
            },
            handleCurrentChange(pageNum) {
                this.pageNum = pageNum;
                this.getUserList();
            },
            editDdialogVisibleColsed() {
                this.$refs.editForm.resetFields();
            },
            submitRegForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.post('/user/addUser',this.regForm).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("添加成功")
                                this.$refs[formName].resetFields()
                                this.dialogVisible=false
                                this.getUserList();
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            editUser(row){
                this.editDdialogVisible= true
                this.$http.get('user/getList',{params:{userName:row.userName}}).then(res => {
                    if(res.data.code === 200){
                        this.editForm = res.data.data[0]
                    }else{
                        this.$message.error('获取失败')
                    }
                })

            },
            submitEditForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.post('/user/updateUser',this.editForm).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("修改成功")
                                this.$refs[formName].resetFields()
                                this.editDdialogVisible=false
                                this.getUserList();
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            getUserList(){
                this.$http.get('user/getListFy',{params:{
                        userName:this.queryCommodityForm.userName,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize
                }}).then(res => {
                    if(res.data.code === 200){
                        this.UserList = res.data.data.rows
                        this.pageNum = res.data.data.pageNum;
                        this.total = res.data.data.total;
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },
            deleteUser(row){
                this.$confirm('确定删除该用户？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=> {
                    this.$http.delete('/user/delete/'+row.id).then(res => {
                        if(res.data.code === 200){
                            this.getUserList()
                        }
                    });
                    this.$message({
                        type: 'success',
                        message: '删除成功!',
                    });
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            show(){
                this.dialogVisible= true
            },
        }
    }
</script>

<style scoped>

</style>