<template>
  <div class="home" style="height: 100%">
    <el-container style="height: 100%">
      <el-header>
        <div class="divCss">
          <el-button type="text" @click="goWelcome">
            <h2 style="color: white; font-size: 30px; margin-top: 0px">
<!--              后台管理系统-->
            </h2>
          </el-button>
        </div>

          <div class="el-dropdown-link" style="color: #000000">
            {{user.userName}}你好
          </div>

      </el-header>
      <el-container style="margin-top: 60px">
        <el-aside width="200px">
          <!--          <div style="height: 700px">-->
          <el-scrollbar class="customScrollbar" >
            <el-menu
                    style="height: 100%; position: fixed; width: 200px"
                    default-active="2"
                    class="el-menu-vertical-demo"
                    router
                    unique-opened

            >
              <el-submenu index="1" >
                <template slot="title">
                  <i class="el-icon-s-custom" style="margin-left: -5px"></i>
                  <span class="spanCss" style="margin-left: 2px">系统管理</span>
                </template>
                <el-menu-item-group >
                  <el-menu-item
                          index="/userSystem"
                          style="margin-top: -20px;margin-left: 5px"
                  >
                    <i class=" el-icon-s-custom" ></i>
                    <span slot="title" class="innerSpanCss">用户管理</span>
                  </el-menu-item>
                </el-menu-item-group>

                <el-menu-item-group >
                  <el-menu-item
                          index="/imgSystem"
                          style="margin-top: -20px"
                  >
                    <i class="el-icon-picture-outline-round" ></i>
                    <span slot="title" class="innerSpanCss">轮播图管理</span>
                  </el-menu-item>
                </el-menu-item-group>

              </el-submenu>

              <el-submenu index="2" >
                <template slot="title">
                  <i class="el-icon-notebook-2" style="margin-left: -5px"></i>
                  <span class="spanCss" style="margin-left: 2px">导航管理</span>
                </template>

                <el-menu-item-group>
                  <el-menu-item
                          index="/phone"
                          style="margin-top: -20px"
                  >
                    <i class="el-icon-notebook-2" ></i>
                    <span slot="title" class="innerSpanCss">导航管理</span>
                  </el-menu-item>

                </el-menu-item-group>

                <el-menu-item-group>
                  <el-menu-item
                          index="/addShop"
                          style="margin-top: -20px"
                  >
                    <i class="el-icon-plus" ></i>
                    <span slot="title" class="innerSpanCss">导航添加</span>
                  </el-menu-item>

                </el-menu-item-group>
              </el-submenu>

              <el-submenu index="3" >
                <template slot="title">
                  <i class="el-icon-s-order" style="margin-left: -5px"></i>
                  <span class="spanCss" style="margin-left: 2px">绑定管理</span>
                </template>

                <el-menu-item-group>
                  <el-menu-item
                          index="/ddSystem"
                          style="margin-top: -20px"
                  >
                    <i class="el-icon-s-order" ></i>
                    <span slot="title" class="innerSpanCss">绑定管理</span>
                  </el-menu-item>
                </el-menu-item-group>

              </el-submenu>
            </el-menu>
          </el-scrollbar>
          <!--          </div>-->
        </el-aside>

        <el-main>
          <router-view> </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      user: {
        id: "",
        userName: "",
        password: "",
        fullscreen: false,
      },
    };
  },
  methods: {
    goWelcome() {
      this.$router.push("/welcome");
    },
    goRescue() {
        let routeData = this.$router.resolve({ path: '/rhome'});
        window.open(routeData.href, '_blank');
    },
    screen() {
   let element = document.documentElement;
   if (this.fullscreen) {
    if (document.exitFullscreen) {
     document.exitFullscreen();
    } else if (document.webkitCancelFullScreen) {
     document.webkitCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
     document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
     document.msExitFullscreen();
    }
   } else {
    if (element.requestFullscreen) {
     element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
     element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
     element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
     // IE11
     element.msRequestFullscreen();
    }
   }
   this.fullscreen = !this.fullscreen;
  },
  },
  created() {
    let user = JSON.parse(window.sessionStorage.getItem("user"));
    if (user != null) {
      this.user = user;
    }
  },
};
</script>
<!--scoped表示此页面样式对本页面有效-->
<style scoped>

.el-header,
.el-footer {
  background-color: #2745d7;
  color: #333;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;
}

.el-aside {
  background-color: white;
  color: #333;

  line-height: 200px;
}

.el-main {
  background-color: white;
  color: #333;
}
.divCss {
  display: flex;
  justify-content: space-between;
}
.spanCss {
  margin-left: 10px;
  font-size: 17px;
}
.innerSpanCss {
  margin-left: 8px;
}
.customScrollbar{
  height:100%
}
</style>