<template>
  <div class="addCommodity">

    <el-card class="box-card">
      <div slot="header">
        <h2>添加导航</h2>
      </div>
      <el-form
        :model="addCommodityForm"
        :rules="regRules"
        ref="addCommodityForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="导航名称" prop="name">
          <el-input v-model="addCommodityForm.name"></el-input>
        </el-form-item>
        <el-form-item label="背景颜色" prop="color">
          <el-color-picker v-model="addCommodityForm.color" ></el-color-picker>
        </el-form-item>
        <el-form-item label="排序号" prop="pxh">
          <el-input v-model="addCommodityForm.pxh"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="urlpath">
          <el-input v-model="addCommodityForm.urlpath"></el-input>
        </el-form-item>
        <el-form-item label="图标">
          <el-button
            :type="addCommodityForm.savepath == '' ? 'danger' : 'success'"
            style="width: 100%"
            @click="showSavepath()"
            >上传图标</el-button
          >
        </el-form-item>


       <div style="text-align: right">
         <el-form-item>
           <el-button>重置</el-button>
           <el-button
                   type="primary"
                   @click="submitCommodityForm('addCommodityForm')"
           >添加</el-button
           >
         </el-form-item>
       </div>
      </el-form>
    </el-card>
    <!--产品封面-->
    <el-dialog title="图标" :visible.sync="showPicVisible" width="30%">
      <el-upload
        :action="path + '/user/upload'"
        list-type="picture-card"
        :on-success="success"
        :limit="1"
        :on-exceed="savepathExceed"
        :on-remove="savepathRemove"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </el-dialog>
    <!--产品相册-->
    <el-dialog
      title="产品相册最多上传二十张"
      :visible.sync="showPicsVisible"
      width="50%"
    >
      <el-upload
        :action="path + '/user/upload'"
        list-type="picture-card"
        :on-success="picssuccess"
        :limit="20"
        :on-exceed="picsExceed"
        :on-remove="picsRemove"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPicsVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "AddCommodity",
  data() {
    return {
      regRules:
              {
                name: [
                  { required: true, message: '请输入藏品名称', trigger: 'blur' },
                  { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                ],
                jg: [
                  { required: true, message: '请输入单个积分', trigger: 'blur' },
                ],
              },
      showPicVisible: false,
      showPicsVisible: false,
      addCommodityForm: {
        id: "",
        name: "",
        color: "#e0e0e0",
        pxh: "",
        savepath: "",
        urlpath:''
      },
      addCommodityRules: {},
      petTypes: [],
      queryCommodityForm: {
        fname: ''
      },
    };
  },
  methods: {
    showSavepath() {
      this.showPicVisible = true;
    },
    success(response, file) {
      this.addCommodityForm.savepath = response.data;
    },
    savepathRemove() {
      this.addCommodityForm.savepath = "";
    },
    savepathExceed() {
      this.$message.error("只能上传一张图片");
    },
    showPics() {
      this.showPicsVisible = true;
    },
    picssuccess(respone, file) {
      this.addCommodityForm.pics.push(respone.data);
    },
    picsExceed() {
      this.$message.error("只能上传二十张图片");
    },
    picsRemove(file) {
      let _this = this;
      this.addCommodityForm.pics.forEach(function (pic, index) {
        if (pic === file.response.data) {
          _this.addCommodityForm.pics.splice(index, 1);
         
        }
      });
    },

    queryDict(){
      this.$http.get('user/getSJiList',{params:{

        }}).then(res => {
        if(res.data.code === 200){
          this.petTypes = res.data.data
        }else{
          this.$message.error('获取列表失败')
        }
      })
    },
    submitCommodityForm(formName) {
      this.$refs[formName].validate(valid=>{
        this.$http
                .post("/shop/addShop", this.addCommodityForm)
                .then((res) => {
                  if (res.data.code === 200) {
                    this.$message.success("添加成功");
                    location.reload()
                  }
                });
          })
    },
  },
  created() {
    this.queryDict();
  },
};
</script>

<style scoped>
.el-dialog__headerbtn {
  margin-top: 0px;
}
  .box-card{
    height: 600px;
  }
</style>