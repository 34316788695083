<template>
    <div>
        <el-card class="box-card">
            <div slot="header" >
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>绑定管理</el-breadcrumb-item>
                    <el-breadcrumb-item>绑定管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-form :model="queryCommodityForm" size="small" ref="queryCommodityForm" class="demo-ruleForm">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <el-form-item  prop="name">
                                <el-input clearable v-model="queryCommodityForm.name" style="width: 130px" placeholder="绑定名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item  prop="userName">
                                <el-input clearable v-model="queryCommodityForm.phone" style="width: 150px" placeholder="手机号码"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item  prop="status">
                                <el-select clearable v-model="queryCommodityForm.type" placeholder="类型">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" icon="el-icon-search" size="small" @click="getUserList()">查询</el-button>
                        </el-col>

                    </el-row>
                </el-form>
                <el-table
                        height="450"
                        :data="UserList"
                        border
                        stripe
                        fit="false"
                        highlight-current-row
                        @row-click="handelClick"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        style="width: 100%">
                    <el-table-column
                            prop="name"
                            label="绑定账号"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="password"
                            label="绑定密码"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="phone"
                            label="手机号码"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="type"
                            label="绑定类型"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="createdtime"
                            label="绑定时间"
                            width="180"
                    >
                    </el-table-column>



                </el-table>
                <div style="margin-left: 5rem; margin-top: 1em">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageNum"
                            :page-sizes="[8,20,50,100]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-card>
        <el-dialog
            title="修改数据"
            :visible.sync="editDdialogVisible"
            width="60%"
            @close="editDdialogVisibleColsed"
    >
     <div style="margin-top: 20px">
         <el-form :model="nowData" size="small" ref="nowData" :rules="regRules">
             <el-form-item  prop="name" label="藏品名称:">
                 <el-input v-model="nowData.name" style="width: 100%" disabled></el-input>
             </el-form-item>
             <el-form-item  prop="user_name" label="购买人:">
                 <el-input v-model="nowData.user_name"  disabled></el-input>
             </el-form-item>

             <el-form-item  prop="gmsl" label="购买数量:">
                 <el-input v-model="nowData.gmsl"  ></el-input>
             </el-form-item>

         </el-form>
     </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="editDdialogVisible = false" size="small">取 消</el-button>
    <el-button type="primary" @click="submitEditForm('nowData')" size="small">确 定</el-button>
  </span>
    </el-dialog>

    </div>
</template>

<script>
    export default {
        data() {

            return {
                regRules:
                    {
                        gmsl: [
                            { required: true, message: '请输入购买数据', trigger: 'blur' },
                        ],

                    },
                options:[
                    {
                        value: '密码绑定',
                        label: '密码绑定'
                    }, {
                        value: '手机绑定',
                        label: '手机绑定'
                    },
                ],
                queryCommodityForm: {
                    name: '',
                    phone:'',
                    type:''
                },
                nowData:{},
                UserList: [],
                regForm: {
                    userName:'',
                    password:'',
                    role:"",
                    jsr:''
                },
                editForm:{
                    userName:'',
                    password:'',
                    role:'',
                    jsr:''
                },
                loading: true,
                total: 0,
                pageNum: 1,
                pageSize: 8,
                fileList:[],
                dialogVisible:false,
                editDdialogVisible: false
            }
        },
        created() {
            this.getUserList()
        },
        methods: {
            handelClick(row){
                this.nowData = row
            },
            // 关闭对话框清空数据
            dialogColsed() {
                this.$refs.regForm.resetFields();

            },
            handleSizeChange(pageSize) {
                this.pageSize = pageSize;
                this.getUserList();
            },
            handleCurrentChange(pageNum) {
                this.pageNum = pageNum;
                this.getUserList();
            },
            editDdialogVisibleColsed() {
                this.$refs.editForm.resetFields();
            },
            submitRegForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.post('/user/addUser',this.regForm).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("添加成功")
                                this.regForm.pic=""
                                this.$refs[formName].resetFields()
                                this.dialogVisible=false
                                this.getUserList();
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            editUser(row){
                this.editDdialogVisible= true
                this.$http.get('user/getList',{params:{userName:row.userName}}).then(res => {
                    if(res.data.code === 200){
                        this.editForm = res.data.data[0]
                    }else{
                        this.$message.error('获取失败')
                    }
                })

            },
            submitEditForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.post('/shop/updateDd',this.nowData).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("修改成功")
                                this.$refs[formName].resetFields()
                                this.editDdialogVisible=false
                                this.getUserList();
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            getUserList(){
                this.$http.get('shop/getListDingDan',{params:{
                        name:this.queryCommodityForm.name,
                        type:this.queryCommodityForm.type,
                        phone:this.queryCommodityForm.phone,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize
                }}).then(res => {
                    if(res.data.code === 200){
                        this.UserList = res.data.data.rows
                        this.pageNum = res.data.data.pageNum;
                        this.total = res.data.data.total;
                        this.loading = false
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },

            del(row){
                if(this.nowData.id == '' || this.nowData.id == undefined){
                    this.$message.error("请选选择数据！！")
                    return
                }
                if(this.nowData.status == '已审核'){
                    this.$message.error("此数据已经审核，不能删除！！")
                    return
                }
                this.$confirm('确定删除该数据吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=> {
                    this.$http.delete('/shop/deleteDd/'+this.nowData.id).then(res => {
                        if(res.data.code === 200){
                            this.$message({
                                type: 'success',
                                message: '删除成功!',
                            });
                            this.getUserList()
                        }else{
                            this.$message({
                                type: 'error',
                                message: '数据异常!',
                            });
                        }
                    });

                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },

            examine(row){
                if(this.nowData.id == '' || this.nowData.id == undefined){
                    this.$message.error("请选选择数据！！")
                    return
                }
                if(this.nowData.status == '已审核'){
                    this.$message.error("此数据已经审核！！")
                    return
                }
                this.$confirm('确定审核该数据吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=> {
                    this.$http.post('/shop/examine/', this.nowData).then(res => {
                        if(res.data.code === 200){
                            this.$message({
                                type: 'success',
                                message: '审核成功!',
                            });
                            this.getUserList()
                        }else{
                            this.$message({
                                type: 'error',
                                message: '数据异常!',
                            });
                        }
                    });

                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },

            cancelExamine(row){
                if(this.nowData.id == '' || this.nowData.id == undefined){
                    this.$message.error("请选选择数据！！")
                    return
                }
                if(this.nowData.status == '未审核'){
                    this.$message.error("此数据未审核！！")
                    return
                }
                this.$confirm('确定取消审核该数据吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=> {
                    this.$http.post('/shop/cancelExamine/', this.nowData).then(res => {
                        if(res.data.code === 200){
                            this.$message({
                                type: 'success',
                                message: '取消审核成功!',
                            });
                            this.getUserList()
                        }else{
                            this.$message({
                                type: 'error',
                                message: '数据异常!',
                            });
                        }
                    });

                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            show(){
                if(this.nowData.id == '' || this.nowData.id == undefined){
                    this.$message.error("请选选择数据！！")
                    return
                }
                if(this.nowData.status == '已审核'){
                    this.$message.error("此数据已审核，不能修改！！！")
                    return
                }
                this.editDdialogVisible= true
            },
        }
    }
</script>

<style scoped>

</style>