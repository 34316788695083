<template>
    <div>
        <el-card class="box-card">
            <div slot="header">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>系统管理</el-breadcrumb-item>
                    <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <div>
                    <el-card>
                        <div>轮播图管理</div>
                        <div style="margin-top: 3%" class="home">
                            <div v-for="item in lbtList" :key="item.id" style=" margin-right: 10px;">
                                <el-card>
                                    <img :src="path+item.savepath" style="width: 150px;height: 110px">

                                    <div style="text-align: center;margin-top: 10%">
                                        <el-button
                                                size="mini"
                                                type="success"
                                                @click="editUser(item)">管理
                                        </el-button>
                                    </div>
                                </el-card>

                            </div>
                        </div>
                    </el-card>

                </div>

                <div>
                    <el-card>
                        <div>联合车险图管理</div>
                        <div style="margin-top: 3%" class="home">
                            <div v-for="item in jgbList" :key="item.id" style=" margin-right: 10px;">
                                <el-card>
                                    <img :src="path+item.savepath" style="width: 150px;height: 110px">

                                    <div style="text-align: center;margin-top: 10%">
                                        <el-button
                                                size="mini"
                                                type="success"
                                                @click="editUser(item)">管理
                                        </el-button>
                                    </div>
                                </el-card>

                            </div>
                        </div>
                    </el-card>

                </div>


            </div>
        </el-card>

        <el-dialog
                title="修改图片"
                :visible.sync="editDdialogVisible"
                width="40%"
                @close="editDdialogVisibleColsed"
        >
            <el-form :model="editForm" rules="regRules" ref="editForm">
                <el-form-item prop="savepath">
                    <el-upload
                            ref='upload'
                            :action="path + '/user/upload'"
                            list-type="picture-card"
                            :on-success="success"
                            :limit="1"
                            :on-exceed="savepathExceed"
                            :on-remove="savepathRemove"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="editDdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitEditForm('editForm')">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {

            return {

                regRules:
                    {
                        savepath: [
                            { required: true, message: '请上传头像', trigger: 'blur' },

                        ],

                    },
                dhList: [],
                lbtList: [],
                jgbList: [],
                editForm: {
                    id:'',
                    savepath:'',

                },
                jiuPath:'',
                editDdialogVisible: false
            }
        },
        created() {
            this.getUserList()
        },
        methods: {
            // 关闭对话框清空数据
            editDdialogVisibleColsed() {
                this.$refs.editForm.resetFields();
                this.$refs.upload.clearFiles();
            },

            editUser(row) {
                this.editDdialogVisible = true
                        this.$nextTick(() => {
                            this.editForm.id = row.id
                            this.jiuPath =  row.savepath
                        })

            },
            success(response, file) {
                this.editForm.savepath = response.data;
            },
            savepathExceed() {
                this.$message.error("只能上传1张");
            },
            savepathRemove(file) {

            },
            submitEditForm(formName) {

                if(this.editForm.savepath ==this.jiuPath || this.editForm.savepath==''){
                    this.$message.error("请上传图片")
                    return
                }
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        this.$http.post('/user/updateImg', this.editForm).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("修改成功")
                                this.$refs.upload.clearFiles();
                                this.editDdialogVisible = false
                                this.getUserList()
                            } else {
                                this.$message.error(res.data.data)
                            }
                        })
                    } else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            getUserList() {
                this.$http.get('user/getListImg',).then(res => {
                    if (res.data.code === 200) {
                        this.lbtList = res.data.data.lbt
                        this.jgbList = res.data.data.jgb
                    } else {
                        this.$message.error('获取列表失败')
                    }
                })
            },

        }
    }
</script>

<style scoped>
    .home {
        display: flex;

    }
</style>